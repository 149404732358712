@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 16px;
}

.custom-break {
  word-break: break-word;
  word-wrap: break-word;
}